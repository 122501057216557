import React from 'react';
import marked from 'marked';
import {Chart} from "chart.js";

import { addRegions } from 'components/BaseEntities/YMap';
import compareArrays from 'utils/compareArrays.js';


const chartOptions = {
  legend: {display: false},
  tooltips: {enabled: false},
  hover: {mode: null},
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  responsive: false
};


export const pieChartMapMixin = Base => class extends Base {

  piesDataHash = {};
  piesObjects = {};

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    // Append pie charts to the Yandex map baloon if it is in DOM,
    // after React's render completed
    for (const k of Object.keys(this.piesDataHash)) {
      const ctx = document.getElementById("pie-chart-" + k);
      if (ctx && prevProps.descriptions.opened[k]) {
        if (this.piesObjects[k]) {
          this.piesObjects[k].ctx = ctx.getContext('2d');
          this.piesObjects[k].update();
        } else {
          const config = {
            type: "pie",
            data: this.piesDataHash[k],
            options: chartOptions
          };
          this.piesObjects[k] = new Chart(ctx, config);
        }
      }
    }

    // add regions to map
    let itemProps = [];
    for (const item of prevProps.items)
      itemProps.push(item.id);

    if (this._map && !compareArrays(this.itemsPrev, itemProps)) {
      if (this.osmArrayPrev) {
        for (const op of this.osmArrayPrev) {
          if (op._collection)
            op._collection.remove();
        }
      }
      if (this.osmArray) {
        addRegions(this._map, this.osmArray, this.osmRegion);
        this.osmArrayPrev = this.osmArray;
      }
      this.itemsPrev = itemProps;
    }
  }

  handleBalloonClose(e1, marker) {
    for (const k of Object.keys(this.piesObjects)) {
        if (this.piesObjects[k]) {
            this.piesObjects[k].destroy();
            this.piesObjects[k] = undefined;
        }
    }
    super.handleBalloonClose(e1, marker);
  }

  assembleInfo(item, meta, description) {
    const { marks, characteristics, media, header } = this.assembleInfoVars(item, meta, description),
          chd = this.getChartData(item),
          exRibbons = this.exRibbons(marks),
          exAttrs = this.exAttrs(characteristics);

    if (chd.charts)
      this.piesDataHash[item.id] = chd.charts;

    return (
      <div className={item.extra.group_size ? "ex-map-info ex-catalog-raiting-item-block ex-catalog-item-variants" :
          "ex-map-info ex-catalog-raiting-item-block"}
           style={item.extra.group_size && {cursor: 'pointer'}}>
        {media && <div className="ex-map-img"
             dangerouslySetInnerHTML={{__html: marked(media, {sanitize: false})}} />}
        {exRibbons}
        <div className="ex-map-descr">
          <h4 className="ex-map-descr-title">{header}</h4>
          {chd.total  ?
          <div className="chartPieListContainer">
            {chd.leadEl}
            <div className="chartPieList d-flex justify-content-start align-items-center">
              <canvas className="chartPieComponent"
                      id={"pie-chart-" + item.id.toString()}
                      width={110} height={110}>
              </canvas>
              <div className="chartPieListLegend">
                {chd.charts.labels.map((legend, i)=>
                  <p key={i}>
                    <span className="chartPieLegendColor"
                          style={{background:chd.charts.datasets[0].backgroundColor[i]}}>
                    </span>
                    <span className="chartPieLegendText">
                      {legend}: </span><span>{Number(
                        (chd.charts.datasets[0].data[i]/(chd.total/100)).toFixed(2)
                      )}<small>%</small>
                    </span>
                  </p>
                  )}
                  {chd.summary}
              </div>
            </div>
          </div>
          : exAttrs}
        </div>
      </div>
    );
  }
};

