import React, { Component } from 'react';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';


// Container

export default class PollList extends Component {
  render() {
    const { items, actions, loading, descriptions, meta, data_mart } = this.props;
    let entities_class = "entities list-items poll-list";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
          <PollListItem
            key={i}
            data={child}
            actions={actions}
            descriptions={descriptions}
            next_url={data_mart.next_url}
            target_id={data_mart.target_id}
          />
        )}
      </div>
    );
  }
}

// Element

class PollListItem extends ListItemMixin(Component) {

  getItemBlock(url, data, title, descriptionBaloon){
    return (
      <div className="col-md-9">
        <a href={url}>
          <h4>{title}{data.extra.is_taken && <i className="fa fa-check-circle" />}</h4>
        </a>
        {descriptionBaloon}
      </div>
    )
  }

  getExTags(marks, data){
    return(
      <ul className="ex-tags">
        {marks.map(
          (child, i) =>
            <li className="ex-tag"
                key={i}
                data-name={child.name}
                data-path={child.path}
                data-view-class={child.view_class.join(" ")}>
              <i className="fa fa-tag"/>&nbsp;
              {child.values.join(", ")}
            </li>
        )}
        {data.extra.is_taken ?
            <li className="ex-tag" key={0}>
              <i className="fa fa-tag"/>&nbsp;Вы уже проходили этот опрос
            </li>
        : ''}
      </ul>
    )
  }

  render() {
    const { data, descriptions, position, meta, next_url, target_id } = this.props,
          groupSize = data.extra.group_size || 0;

    let url = data.entity_url;
    if (data.extra && data.extra.url) {
      url = data.extra.url;
      if (target_id)
        url += `${target_id}/`;
    }

    if (next_url)
      url += `?next=${next_url}`;

    let groupDigit = "";
    if (groupSize) {
      groupDigit = (
        <div className="ex-pack">
          <span className="ex-digit">{groupSize}</span>
          <div><div><div></div></div></div>
        </div>
      );
    }

    let characteristics = data.short_characteristics || [],
        marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
          (descriptions.opened[data.id] ? " ex-state-description" : ""),
          wrapperClassName = "wrap-list-item" + (!data.extra.is_valid ? " non-valid" : "");

    // меняем адрес ссылки картинки на адрес с target_id
    // todo: поправить components/BaseEntities/ListItemMixin убрать лишнюю ссылку
    const reg = new RegExp("(.*?)(href=\"\/.*\/\")(.*)");
    data.media = data.media.replace(reg, `$1href="${url}"$3`);

    const exAttrs = this.getExAttrs(data, characteristics),
          exTags = this.getExTags(marks, data),
          descriptionBaloon = this.getDescriptionBaloon(data, characteristics, marks, descriptions, exAttrs, exTags) || "",
          title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
          itemBlock = this.getItemBlock(url, data, title, descriptionBaloon),
          itemContent = this.getItemContent(url, data, itemBlock, marks,wrapperClassName);

    return (
      <div className={className}
         onMouseOver={e => this.handleMouseOver(e)}
         onMouseOut={e => this.handleMouseOut(e)}
         style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}
