import React, { Component } from 'react';

// Table component

export default class Table extends Component {
  render() {
    const { items, actions, descriptions } = this.props;

    return (
      <table className="table-bordered">
        <tbody>
          {items.map(
            (child, i) =>
            <Row key={i} data={child} actions={actions} descriptions={descriptions}/>
          )}
        </tbody>
      </table>
    );
  }
}

class Row extends Component {
  render() {
    let data = this.props.data;
    return (
      <tr>
        <td>
          <a href={data.entity_url}>{data.entity_name}</a>
        </td>
      </tr>
    )
  }
}