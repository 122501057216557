import React, { Component } from 'react';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';
import EntityRatingListItemMixin from "./EntityRatingListItemMixin";


// Container

export default class ParticularInitiativeList extends Component {

  render() {
    const { items, actions, loading, descriptions, meta } = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
          <ParticularInitiativeListItem
              key={i}
              data={child}
              actions={actions}
              loading={loading}
              descriptions={descriptions}
              position={i}
              meta={meta}
          />
        )}
      </div>
    );
  }
}

// Element

class ParticularInitiativeListItem extends EntityRatingListItemMixin(ListItemMixin(Component)) {

  getExAttrs(data, characteristics){
    let annotations = {};
    if (data.extra) {
      for (const [key, val] of Object.entries(data.extra)) {
        if (val instanceof Object && 'name' in val && 'value' in val)
          annotations[key] = val;
      }
    }

    return(
      <ul className="ex-attrs">
        {Object.keys(annotations).length !== 0 &&
          <li className="annotation">
            {Object.keys(annotations).map(
              (key, i) =>
                <div key={i}>
                  <strong>{annotations[key].name}:&nbsp;</strong>
                  {annotations[key].value instanceof Array ?
                    annotations[key].value.map((val, key) => <span key={key}>{val};&nbsp;</span>)
                  :
                    <span key={key}>{annotations[key].value}</span>
                  }
                </div>
            )}
          </li>
        }
        {characteristics.map(
          (child, i) =>
            <li data-path={child.path} key={i}
              data-view-class={child.view_class.join(" ")}>
              <strong>{child.name}:&nbsp;</strong>
              {child.values.join("; ")}
            </li>
        )}
      </ul>
    )
  }

  render() {
    const { data, meta, descriptions } = this.props,
          url = data.extra && data.extra.url ? data.extra.url : data.entity_url,
          groupSize = data.extra.group_size || 0;

    let groupDigit = "";
    if (groupSize) {
      groupDigit = (
        <div className="ex-pack">
          <span className="ex-digit">{groupSize}</span>
          <div><div><div></div></div></div>
        </div>
      );
    }

    let characteristics = data.short_characteristics || [],
        shortCharacteristics = characteristics,
        marks = data.short_marks || [];
    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
        (descriptions.opened[data.id] ? " ex-state-description" : "");

    const exAttrs = this.getExAttrs(data, characteristics);
    const displayExRibbons = marks.length !== 0;

    const itemData = this.votingData(data),
          descriptionBaloon = this.getDescriptionBaloon(characteristics, marks, itemData),
          title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
          itemBlock = this.getItemBlock(url, data, title, descriptions, descriptionBaloon, exAttrs),
          itemContent = this.getItemContent(itemData, marks, url, data, itemBlock, displayExRibbons);

    return (
      <div className={className}
         onMouseOver={e => this.handleMouseOver(e)}
         onMouseOut={e => this.handleMouseOut(e)}
         style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}
