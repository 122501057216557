import React, {Component} from 'react';
import ParticularProblemItemMixin from './ParticularProblemItemMixin';
import TileItemMixin from 'components/BaseEntities/TileItemMixin';


// Container

export default class ParticularProblemTile extends Component {

  render() {
    const {items, actions, loading, descriptions, meta} = this.props;
    let entities_class = "entities ex-tiles";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <ul className={entities_class}>
        {items.map(
          (child, i) =>
            <ParticularProblemTileItem key={i} data={child} actions={actions} descriptions={descriptions} position={i}
                                       meta={meta}/>
        )}
      </ul>
    );
  }
}

// Element

class ParticularProblemTileItem extends ParticularProblemItemMixin(TileItemMixin(Component)) {

  handleMouseOver(e) {
    const {data, actions, descriptions} = this.props,
      id = data.id;

    actions.showDescription(id);
    !descriptions[id] && actions.getEntityInfo(data);
  }

  handleMouseOut(e) {
    const {data, actions} = this.props;
    actions.hideDescription(data.id);
  }

  getDescriptionBaloon(exAttrs) {
    return (
      <div className="ex-description-wrapper">
        <div className="ex-baloon">
          <div className="ex-arrow"/>
          {exAttrs}
        </div>
      </div>
    )
  }

  getItemBlock(descriptionBaloon, itemContent) {
    return (
      <div className="ex-catalog-item-block">
        {descriptionBaloon}
        {itemContent}
      </div>
    )
  }

  render() {
    const {data, descriptions, position, meta} = this.props,
      index = position + meta.offset;

    let liClass = "ex-catalog-item";
    if (descriptions.opened[data.id])
      liClass += " ex-state-description";

    let characteristics = data.short_characteristics || [],
      marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const exAttrs = this.getExAttrs(data, characteristics),
      descriptionBaloon = characteristics.length ? this.getDescriptionBaloon(exAttrs) : null,
      title = data.entity_name,
      itemContent = this.getItemContent(data, title, marks),
      itemBlock = this.getItemBlock(descriptionBaloon, itemContent);

    return (
      <li className={liClass}
          data-horizontal-position={this.state.h_pos}
          data-vertical-position="center"
          data-index={index}
          onMouseOver={e => ::this.handleMouseOver(e)}
          onMouseOut={e => ::this.handleMouseOut(e)}>
        {itemBlock}
      </li>
    );
  }
}
