import cookie from 'react-cookies';


const resolveJson = async (response) => {
  let json;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error(error);
  }
  return json;
}

const addAuthToken = (val) => {
  const csrftoken = cookie.load('csrftoken'),
    token = cookie.load('sessionid2');

  if (token)
    val['Authorization'] = `Token ${token}`;
  if (csrftoken)
    val['X-CSRFToken'] = csrftoken;

  return val
};

export default async (url, method = "GET", data = "", isAddAuthToken = false) => {
  const params = {
    method,
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (["PATCH", "POST"].includes(method)) {
    params.headers['X-CSRFToken'] = cookie.load('csrftoken');
    params.body = JSON.stringify(data);
  }

  if (isAddAuthToken)
    params.headers = addAuthToken(params.headers);

  let response;
  try {
    response = await fetch(url, params);
  } catch (error) {
    throw error;
  }

  const json = await resolveJson(response);
  if (response.ok)
    return json;

  const msg = typeof data === "object" ? Object.values(json)[0] : json[0];
  throw JSON.stringify(msg);
}