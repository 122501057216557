import BaseRoot from 'components/BaseRoot';

const Root = BaseRoot;
export default Root;



// import React, { Component } from 'react';
// import BaseRoot from 'containers/BaseRoot';
//
//
// export default class Root extends Component {
//
//     static getTemplates() {
//         return BaseRoot.getTemplates();
//     }
//
//     render() {
//         return <BaseRoot {...this.props} getTemplates={Root.getTemplates}/>
//     }
// }



