import React, {Component} from 'react';
import marked from 'marked';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';
import uniFetch from '../../utils/uniFetch';


export default class DiscussionInitiativeList extends Component {
  render() {
    const {items, actions, loading, descriptions, meta, data_mart} = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
            <DiscussionInitiativeListItem
              key={i}
              data={child}
              meta={meta}
              actions={actions}
              descriptions={descriptions}
              pk={data_mart.pk}
              next_url={data_mart.next_url}
            />
        )}
      </div>
    );
  }
}

class DiscussionInitiativeListItem extends ListItemMixin(Component) {

  constructor() {
    super();
    this.state = {
      userIsModerator: false,
      btnReportText: 'Сформировать отчет',
      btnReportDisabled: false
    }
  }

  componentDidMount() {
    this.setState({
      userIsModerator: document.querySelector('body').classList.contains('moderateperson')
    })
  }

  generateReport(id) {
    this.setState({
      btnReportText: 'Отчет формируется',
      btnReportDisabled: true
    })
    const urlGenerateReport = '/api/reports/generate/';
    const data = {
      report_key: 200,
      options: {
        selected_id: id
      }
    }
    uniFetch(urlGenerateReport, 'POST', data)
      .then(res => {
        if (res.reason)
          alert(res.reason)
        else
          window.open(res.file_url)

        this.setState({
          btnReportText: 'Сформировать отчет',
          btnReportDisabled: false
        })
      })
      .catch(() => {
        this.setState({
          btnReportText: 'Сформировать отчет',
          btnReportDisabled: false
        })
      })
  }

  getItemBlock(url, data, title, descriptionBalloon) {
    return (
      <div className="wrap-list-item__description">
        <a href={url}>
          <h4>{title}</h4>
        </a>
        {this.state.userIsModerator &&
        <button className={"btn btn-info btn-sm btn btn-info btn-sm mt-2 mb-1"} disabled={this.state.btnReportDisabled}
                onClick={() => this.generateReport(data.id)}>
          {this.state.btnReportText}
        </button>}
        {descriptionBalloon}
      </div>
    );
  }

  getItemContent(url, data, itemBlock, marks, wrapperClassName = 'wrap-list-item') {
    return (
      <div className={wrapperClassName}
           onClickCapture={e => ::this.handleMouseClick(e)}>
        <div className="row wrap-list-item__content">
          <div className="wrap-list-item__image">
            <div className="ex-media" dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}/>
          </div>
          {itemBlock}
        </div>

        <ul className="ex-ribbons">
          {marks.map(
            (child, i) =>
              <li className="ex-wrap-ribbon"
                  key={i}
                  data-name={child.name}
                  data-path={child.path}
                  data-view-class={child.view_class.join(' ')}>
                <div className="ex-ribbon">{child.values.join(', ')}</div>
              </li>
          )}
        </ul>
      </div>
    );
  }

  render() {
    const {data, descriptions, meta, next_url} = this.props,
      groupSize = data.extra.group_size || 0,
      infoUrl = data.extra?.url || data.entity_url;

    let url = next_url ? `${next_url}?budget_initiative_project_id=${data.id}` : infoUrl;
    url += url.includes('?') ? `&next=${url}` : `?next=${url}`;

    let characteristics = data.short_characteristics || [],
      marks = data.short_marks || [];

    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
      (descriptions.opened[data.id] ? " ex-state-description" : "");

    const exAttrs = this.getExAttrs(data, characteristics),
      exTags = this.getExTags(marks),
      descriptionBalloon = this.getDescriptionBaloon(data, characteristics, marks, descriptions, exAttrs, exTags) || "",
      title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
      itemBlock = this.getItemBlock(url, data, title, descriptionBalloon),
      itemContent = this.getItemContent(url, data, itemBlock, marks);

    return (
      <div className={className}
           onMouseOver={e => this.handleMouseOver(e)}
           onMouseOut={e => this.handleMouseOut(e)}
           style={{minHeight: this.state.minHeight}}>
        {itemContent}
      </div>
    );
  }
}
