import React from 'react';


const EntityRatingMixin = Base => class extends Base {

  opinions = {
    'yes': {
        text: 'Yes',
        color: "#32d332"
    },
    'rather_yes': {
        text: 'Rather yes',
        color: '#7CFC00'
    },
    'difficult_to_answer': {
        text: 'Difficult to answer',
        color: '#ffc40d'
    },
    'rather_no': {
        text: 'Rather no',
        color: '#ff6026'
    },
    'no': {
        text: 'No',
        color: '#e20007'
    }
  };

  getVotingData(data){
    const pollsResults = {},
          keys = Object.keys(this.opinions);

    let total = 0;

    for (const k of keys) {
      pollsResults[k] = data.extra[k];
      total += data.extra[k]
    }

    let percentage = null;
    if (total) {
      percentage = 0;
      const pInc = 100 / (keys.length - 1);
      for (let i = 0; i < keys.length - 1; i++)
        percentage += pollsResults[keys[i]] / total * (100 - i * pInc);
      percentage = Math.floor(percentage);
    }

    const {address, measure, measures_count} = data.extra;

    let colorLevel = Math.round((percentage + 10) / 20);

    const chartData = {
      labels: keys.map((k) => gettext(this.opinions[k].text)),
      datasets: [
        {
          key: data.id,
          data: keys.map((k) => pollsResults[k]),
          backgroundColor: keys.map((k) => this.opinions[k].color),
        }
      ],
    };

    switch(colorLevel){
      case 0:
      case 1:
        colorLevel = chartData.datasets[0].backgroundColor[4];
        break;
      case 2:
        colorLevel = chartData.datasets[0].backgroundColor[3];
        break;
      case 3:
        colorLevel = chartData.datasets[0].backgroundColor[2];
        break;
      case 4:
        colorLevel = chartData.datasets[0].backgroundColor[1];
        break;
      default:
        colorLevel = chartData.datasets[0].backgroundColor[0];
    }

    let annotations = {};
    if (data.extra) {
      for (const [key, val] of Object.entries(data.extra)) {
        if (val instanceof Object && 'name' in val && 'value' in val)
          annotations[key] = val;
      }
    }

    const created_at = data.extra.created_at ? new Date(data.extra.created_at) : null;
    const author = data.extra.author;

    return {chartData, colorLevel, total, percentage, measure, measures_count, address, annotations, created_at, author}
  }

  votingData(data) {
    let result = data._cachedVotingData;
    if (!result) {
      data._cachedVotingData = result = this.getVotingData(data);
    }
    return result;
  }
};

export default EntityRatingMixin
