import React, {Component} from 'react';
import marked from 'marked';
import {withYMaps, YMaps} from 'react-yandex-maps';
import {YMapInner} from 'components/BaseEntities/YMap';
import ParticularProblemItemMixin from './ParticularProblemItemMixin';


class PaticularProblemYMap extends ParticularProblemItemMixin(YMapInner) {
  static getMapConfig() {
    return Object.assign(YMapInner.getMapConfig(), {
      behaviors: [
        'drag',
        'dblClickZoom',
        'rightMouseButtonMagnifier',
        'multiTouch',
      ]
    });
  }

  assembleInfo(data, meta, description) {
    const {marks, characteristics, media, header} = this.assembleInfoVars(data, meta, description);
    let exRibbons = this.exRibbons(marks),
      exTags = this.exTags(marks);

    const exAttrs = this.getExAttrs(data, characteristics)

    return (
      <div className="ex-map-info"
           onClick={e => ::this.handleInfoMouseClick(e, data)}
           style={data.extra.group_size && {cursor: 'pointer'}}>
        <div className="ex-map-img" dangerouslySetInnerHTML={{__html: marked(media, {sanitize: false})}}/>
        {exRibbons}
        <div className="ex-map-descr">
          <h5>{header}</h5>
          {exAttrs}
          {exTags}
        </div>
      </div>
    );
  }
}


const YMapWrapped = withYMaps(PaticularProblemYMap, true, ['templateLayoutFactory']);

export default class YMap extends Component {
  render() {
    return (
      <YMaps>
        <YMapWrapped {...this.props} getMapConfig={PaticularProblemYMap.getMapConfig}/>
      </YMaps>
    );
  }
}
