import React from 'react';
import {withYMaps, YMaps} from 'react-yandex-maps';

import {YMapInner} from 'components/BaseEntities/YMap';
import {pieChartMapMixin} from './PieChartMapMixin';

import Color from 'color';


const statusesOfSolving = {
  'it-is-considered': {
    text: gettext("It is considered without delay"), // «рассмотрено без нарушения срока»
    color: "#32d332"
  },
  'on-consideration': {
    text: gettext("On consideration without delay"), // «на рассмотрении без нарушения срока»
    color: "#7CFC00"
  },
  'term-of-consideration-expires': {
    text: gettext("Term of consideration expires"), // «истекает срок рассмотрения»
    color: "#ffc40d"
  },
  'it-is-considered-with-violation-of-term': {
    text: gettext("It is considered with violation of term"), // «рассмотрено с нарушением срока» или «нарушение срока»
    color: "#ff6026"
  },
  'term-of-consideration-has-expired': {
    text: gettext("Term of consideration has expired"), // «истёк срок рассмотрения»
    color: "#e20007"
  }
};

const messageLabel = 'Messages total';
const responsiblesLabel = 'Number of responsibles';
const thematicsLabel = 'Thematics';
const thematicPath = 'problemnaya-tema';
const maxThematicsLength = 3;

const getVotingData = item => {
  const problemsResults = {},
    keys = Object.keys(statusesOfSolving),
    groupSize = item.extra.group_size,
    prefix = groupSize ? 'num_' : '',
    totalProblems = groupSize || 1,
    totalResponsible = groupSize ? item.extra.num_responsibles.value : 1;

  if (groupSize) {
    for (const k of keys)
      problemsResults[`${prefix}${k}`] = item.extra[`${prefix}${k}`].value || 0;
  } else {
    const status = item.extra.status_of_solving;
    for (const k of keys)
      problemsResults[k] = k === status ? 1 : 0;
  }

  let percentage = [];
  for (let i = 0; i < keys.length; i++)
    percentage[i] = problemsResults[`${prefix}${keys[i]}`] / totalProblems;

  let color = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    keys.map((k, j) => {
      const colorStatus = Color(statusesOfSolving[k].color).rgb().array();
      color[i] += Math.round(colorStatus[i] * percentage[j]);
    });
  }

  const whiteness = 1 - Math.min(0.33 * (totalProblems / totalResponsible), 1);

  let groupColor = Color.rgb(color).saturate(0.5);
  const groupBorderColor = Color(groupColor).darken(0.35),
    pinColor = Color(groupColor).hex();

  groupColor = groupColor.lighten(whiteness);
  const regionColor = Color(groupColor).alpha(0.3).rgb().string();

  return {pinColor, groupColor, groupBorderColor, regionColor};
};

const votingData = item => {
  let data = item._cachedVotingData;
  if (!data) {
    item._cachedVotingData = data = getVotingData(item);
  }
  return data;
};


class PaticularProblemYHeatMapInner extends pieChartMapMixin(YMapInner) {

  _DO_CLICK_ON_BALLOON = false;
  BALLOON_SELECTORS = ['.responsible-total', '.message-total', '.ex-map-descr-title'];

  handleBalloonContentClick(event, selector, entity) {
    if (selector === '.responsible-total') {
      let responsiblePersonsUrl = this.props.data_mart.url;
      if (entity.extra.region_id)
        // todo: fix it!!!
        responsiblePersonsUrl += `?foo=1&terms=${entity.extra.region_id},${this.props.meta.terms_ids}`;
      window.location.href = responsiblePersonsUrl;
    }
    if (selector === '.message-total' || selector === '.ex-map-descr-title') {
      const {actions, meta} = this.props;
      if (entity.extra.group_size) {
        actions.notifyLoadingEntities();
        actions.expandGroup(entity.id, meta);
      } else {
        window.location.href = event.target.href;
      }
    }
  }

  getGroupColor(item) {
    const items = votingData(item),
      {groupColor, groupBorderColor, regionColor} = items;
    return {groupColor, groupBorderColor, regionColor};
  }

  getPinColor(item) {
    return votingData(item).pinColor;
  }

  // handleInfoMessageMouseClick(e, data) {
  //   const { actions, meta } = this.props;
  //   if (data.extra.group_size) {
  //     actions.notifyLoadingEntities();
  //     actions.expandGroup(data.id, meta);
  //   }
  // }

  getChartData(item) {
    const total = item.extra && item.extra.group_size;

    if (!total)
      return {total};

    const keys = Object.keys(statusesOfSolving),
      initialLabels = keys.map((k) => statusesOfSolving[k].text),
      initialData = keys.map((k) => item.extra['num_' + k].value),
      initialBackgroundColor = keys.map((k) => statusesOfSolving[k].color);

    // remove zero data
    const labels = [],
      data = [],
      backgroundColor = [];
    for (let i = 0; i < initialData.length; i++) {
      const d = initialData[i];
      if (d) {
        data.push(d);
        labels.push(initialLabels[i]);
        backgroundColor.push(initialBackgroundColor[i]);
      }
    }

    const charts = {
      labels,
      datasets: [
        {
          key: item.id,
          data,
          backgroundColor,
        }
      ],
    };

    let numResponsibles = 0;
    if (item.extra && item.extra.num_responsibles && item.extra.num_responsibles.value)
      numResponsibles = item.extra.num_responsibles.value;

    let thematics = null;
    if (item.short_marks && item.short_marks.length) {
      const mark = item.short_marks.find(t => t.path == thematicPath);
      if (mark)
        if (mark.values.length)
          thematics = mark.values;
    }

    if (thematics && thematics.length > maxThematicsLength) {
      thematics = thematics.slice(0, maxThematicsLength);
      thematics.push('...');
    }

    const leadEl = thematics ? (
      <div className="subtitleList">
        <span><b>{gettext(thematicsLabel)}:</b> {thematics.join(', ')}</span>
      </div>
    ) : null;

    const summary = (<p className="voted">
      <span className="message-total">{gettext(messageLabel)}: <span>{total}</span></span>
      <br/>
      <span className="responsible-total">{gettext(responsiblesLabel)}: <span>{numResponsibles}</span></span>
    </p>);

    return {total, leadEl, charts, summary};
  }
}


const PaticularProblemYHeatMapWrapped = withYMaps(
  PaticularProblemYHeatMapInner, true, ['templateLayoutFactory']
);


export default class YMap extends React.Component {
  render() {
    return (
      <YMaps>
        <PaticularProblemYHeatMapWrapped {...this.props} />
      </YMaps>
    );
  }
}

