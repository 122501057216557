import React from 'react';


const ParticularProblemItemMixin = Base => class extends Base {

  getExAttrs(data, characteristics) {
    const created_at = new Date(data.extra.created_at);
    const need_solve_at = data.extra.need_solve_at ? new Date(data.extra.need_solve_at) : null;

    let annotations = {};
    if (data.extra) {
      for (const [key, val] of Object.entries(data.extra)) {
        if (val instanceof Object && 'name' in val && 'value' in val)
          annotations[key] = val;
      }
    }

    return (
      <ul className="ex-attrs">
        {characteristics.map(
          (child, i) =>
            <li data-path={child.path} key={i}
                data-view-class={child.view_class.join(" ")}>
              <strong>{child.name}:&nbsp;</strong>
              {child.values.join("; ")}
            </li>
        )}
        {Object.keys(annotations).length !== 0 &&
        <li className="annotation">
          {Object.keys(annotations).map(
            (key, i) =>
              <div key={i}>
                <strong>{annotations[key].name}:&nbsp;</strong>
                {annotations[key].value instanceof Array ?
                  annotations[key].value.map((val, key) => <span key={key}>{val};&nbsp;</span>)
                  :
                  <span key={key}>{annotations[key].value}</span>
                }
              </div>
          )}
        </li>
        }
        {need_solve_at &&
        <li>
          <strong>{gettext('Deadline')}:</strong>&nbsp;
          <span>{need_solve_at.getDate() > 9 ? need_solve_at.getDate() : '0' + need_solve_at.getDate()}.</span>
          <span>{need_solve_at.getMonth() + 1 >= 9 ? need_solve_at.getMonth() + 1 : '0' + (need_solve_at.getMonth() + 1)}.</span>
          <span>{need_solve_at.getFullYear()}</span>
          &nbsp;
          <span>{need_solve_at.getHours() > 9 ? need_solve_at.getHours() : '0' + need_solve_at.getHours()}:</span>
          <span>{need_solve_at.getMinutes() > 9 ? need_solve_at.getMinutes() : '0' + need_solve_at.getMinutes()}</span>
        </li>}
        <div className="particular-problem-extra">
          <hr/>
          <strong><i className="fa fa-calendar" aria-hidden="true"/></strong>&nbsp;
          {created_at.getDate() > 9 ? created_at.getDate() : '0' + created_at.getDate()}.
          {created_at.getMonth() + 1 >= 9 ? created_at.getMonth() + 1 : '0' + (created_at.getMonth() + 1)}.
          {created_at.getFullYear()}
          <strong className="second"><i className="fa fa-user-o" aria-hidden="true"/></strong>&nbsp;
          {data.extra.author}
        </div>
      </ul>
    )
  }
};

export default ParticularProblemItemMixin
