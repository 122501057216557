import React, { Component } from 'react';
import marked from 'marked';
import TileItemMixin from 'components/BaseEntities/TileItemMixin';


// Container

export default class PersonTile extends Component {

  render() {
    const { items, actions, loading, descriptions, meta, data_mart } = this.props;
    let entities_class = "entities ex-tiles";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <ul className={entities_class}>
        {items.map(
          (child, i) =>
          <PersonTileItem key={i} data={child} actions={actions} descriptions={descriptions}
            position={i} meta={meta} auth_person={data_mart.auth_person}/>
        )}
      </ul>
    );
  }
}

// Element

class PersonTileItem extends TileItemMixin(Component) {

  handleMouseOver(e) {
    const { data, actions, descriptions } = this.props;
    actions.showDescription(data.id);
    if (!descriptions[data.id])
      actions.getEntityInfo(data);
  }

  handleMouseOut(e) {
    const { data, actions, descriptions } = this.props;
    actions.hideDescription(data.id);
  }

  getDescriptionBaloon(baloonInfo) {
    if (baloonInfo.length) {
      return(
        <div className="ex-description-wrapper">
          <div className="ex-baloon">
            <div className="ex-arrow"></div>
            <ul className="ex-attrs">
              {baloonInfo.map(
                (child, i) => (
                    <li key={i} className={child.view_class}>
                      <strong>{child.name}:</strong>&nbsp;
                    {child.values}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      );
    }
  }

  getItemContent(data, index) {
    const url = data.extra && data.extra.url ? data.extra.url : data.entity_url;
    return(
      <div className="ex-wrap-action">
        <a href={url}>
          <div className="ex-media" dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}></div>
        </a>
        <span className="badge">{index + 1}</span>
        <div className="ex-wrap-title">
          <h4 className="ex-title">
            <a href={url}>{data.entity_name}</a>
          </h4>
        </div>
      </div>
    )
  }

  getItemBlock(descriptionBaloon, itemContent) {
    return(
      <div className="ex-catalog-item-block">
        {descriptionBaloon}
        {itemContent}
      </div>
    )
  }

  render() {
    const {data, descriptions, position, meta} = this.props,
          index = position + meta.offset;

    const userType = this.props.auth_person;

    let liClass = "ex-catalog-item";
    if (descriptions.opened[data.id])
      liClass += " ex-state-description";

    let baloonInfo = [];

    if (data.extra) {
      const {rating} = data.extra.rating ? data.extra : 0,
            {messages_count} = data.extra.messages_count ? data.extra : 0,
            {ideas_count} = data.extra.ideas_count ? data.extra : 0,
            {simple_poll_answer_count} = data.extra.simple_poll_answer_count ? data.extra : 0,
            {last_24_hours_problems_count} = data.extra.last_24_hours_problems_count? data.extra : 0,
            {independent_evaluation_poll_answer_count} = data.extra.independent_evaluation_poll_answer_count ? data.extra : 0,
            {baseperson__privateperson__influence_lvl} = data.extra.baseperson__privateperson__influence_lvl ? data.extra : 0,
            {baseperson__privateperson__followers_cnt} = data.extra.baseperson__privateperson__followers_cnt ? data.extra : 0,
            {baseperson__privateperson__votes_cnt} = data.extra.baseperson__privateperson__votes_cnt ? data.extra : 0;

      if (rating) {
        baloonInfo.push({
          name: gettext('Rating'),
          values: rating,
          view_class: "rating"
        })
      }

      if (baseperson__privateperson__influence_lvl) {
        baloonInfo.push({
          name: gettext('Influence level'),
          values: baseperson__privateperson__influence_lvl ? Math.round(baseperson__privateperson__influence_lvl)  : 0,
          view_class: "influence_lvl"
        })
      }

      if (baseperson__privateperson__followers_cnt) {
        baloonInfo.push({
          name: gettext('Followers'),
          values: baseperson__privateperson__followers_cnt ? baseperson__privateperson__followers_cnt : 0,
          view_class: "followers_cnt"
        })
      }

      if (baseperson__privateperson__votes_cnt) {
        baloonInfo.push({
          name: gettext('Votes'),
          values: baseperson__privateperson__votes_cnt ? baseperson__privateperson__votes_cnt : 0,
          view_class: "votes_cnt"
        })
      }

      if (messages_count) {
        baloonInfo.push({
          name: gettext('Messages count'),
          values: messages_count,
          view_class: "messages_cnt"
        })
      }

      if (userType && userType !== 'privateperson' && last_24_hours_problems_count) {
        baloonInfo.push({
          name: gettext('User\'s last 24 hours messages count'),
          values: last_24_hours_problems_count,
          view_class: "24_hours_messages_cnt"
        })
      }

      if (ideas_count) {
        baloonInfo.push({
          name: gettext('Ideas count'),
          values: ideas_count,
          view_class: "ideas_cnt"
        })
      }

      if (simple_poll_answer_count) {
        baloonInfo.push({
          name: gettext('Simple poll answer count'),
          values: simple_poll_answer_count,
          view_class: "polls_cnt"
        })
      }

      if (independent_evaluation_poll_answer_count) {
        baloonInfo.push({
          name: gettext('Independent evaluation poll answer count'),
          values: independent_evaluation_poll_answer_count,
          view_class: "independents_cnt"
        })
      }
    }

    const descriptionBaloon = this.getDescriptionBaloon(baloonInfo) || "",
          itemContent = this.getItemContent(data, index),
          itemBlock = this.getItemBlock(descriptionBaloon, itemContent);

    return(
      <li className={liClass}
          data-horizontal-position={this.state.h_pos}
          data-vertical-position="center"
          data-index={index}
          onMouseOver={e => { ::this.handleMouseOver(e) } }
          onMouseOut={e => { ::this.handleMouseOut(e) } }>
          {itemBlock}
      </li>
    );
  }
}
