import React, {Component} from 'react';
import marked from 'marked';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';
import {Doughnut, Pie} from "react-chartjs-2";


// Container

export default class ResponsiblePersonList extends Component {

  render() {
    const {items, actions, loading, descriptions, meta} = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <ul className={entities_class}>
        {items.map(
          (child, i) =>
            <ResponsiblePersonListItem key={i} data={child} actions={actions} descriptions={descriptions} position={i}
                                       meta={meta}/>
        )}
      </ul>
    );
  }
}

const SIZE_PIE_CHART = 130,
  SIZE_DOUGHNUT_CHART = 100,
  CHART_OPTIONS = {
    legend: {display: false},
    tooltips: {enabled: false},
    hover: {mode: null},
    animation: {duration: 0},
    elements: {
      arc: {borderWidth: 0}
    }
  };

// Element

export class ResponsiblePersonListItem extends ListItemMixin(Component) {

  regulation = {
    'num_it-is-considered': {
      text: gettext("It is considered without delay"), // «рассмотрено без нарушения срока»
      color: "#32d332"
    },
    'num_on-consideration': {
      text: gettext("On consideration without delay"), // «на рассмотрении без нарушения срока»
      color: "#7CFC00"
    },
    'num_term-of-consideration-expires': {
      text: gettext("Term of consideration expires"), // «истекает срок рассмотрения»
      color: "#ffc40d"
    },
    'num_it-is-considered-with-violation-of-term': {
      text: gettext("It is considered with violation of term"), // «рассмотрено с нарушением срока» или «нарушение срока»
      color: "#ff6026"
    },
    'num_term-of-consideration-has-expired': {
      text: gettext("Term of consideration has expired"), // «истёк срок рассмотрения»
      color: "#e20007"
    }
  };

  getVotingData(data) {
    const results = {},
      keys = Object.keys(this.regulation);

    let total = 0;

    for (const k of keys) {
      results[k] = data.extra[k];
      total += data.extra[k].value;
    }

    const chartData = {
      labels: keys.map((k) => gettext(this.regulation[k].text)),
      datasets: [
        {
          key: data.id,
          data: keys.map((k) => results[k].value),
          backgroundColor: keys.map((k) => this.regulation[k].color),
        }
      ],
    };

    let percentage = 0;
    // Берем в расчет только первые 2 параметра и половину от третьего. Другие игнорируем
    for (let k = 0; k < 3; k++)
      if (results[keys[k]].value)
        percentage += keys[k] !== 'num_term-of-consideration-expires' ? results[keys[k]].value / total * 100 :
          results[keys[k]].value / total * 50;

    percentage = Math.floor(percentage);

    let colorLevel = Math.round((percentage + 10) / 20);

    switch (colorLevel) {
      case 0:
      case 1:
        colorLevel = chartData.datasets[0].backgroundColor[4];
        break;
      case 2:
        colorLevel = chartData.datasets[0].backgroundColor[3];
        break;
      case 3:
        colorLevel = chartData.datasets[0].backgroundColor[2];
        break;
      case 4:
        colorLevel = chartData.datasets[0].backgroundColor[1];
        break;
      default:
        colorLevel = chartData.datasets[0].backgroundColor[0];
    }

    const numProblem = data.extra['num_problem'].value ? data.extra['num_problem'].value : 0;

    return {chartData, total, percentage, colorLevel, numProblem}
  }

  votingData(data) {
    let result = data._cachedVotingData;
    if (!result)
      data._cachedVotingData = result = this.getVotingData(data);
    return result;
  }

  getShortDescription(shortCharacteristics) {
    if (shortCharacteristics.length) {
      return (
        <div className="short-description">
          <ul className="ex-attrs">
            {shortCharacteristics.map(
              (child, i) =>
                child.values.length < 5 ?
                  <li data-path={child.path} key={i}
                      data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:</strong>&nbsp;
                    {child.values.join("; ")}
                  </li>
                  :
                  <li data-path={child.path} key={i}
                      data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:</strong>&nbsp;
                    {child.values.join("; ").split('; ', 5).join("; ")}...
                  </li>
            )}
          </ul>
        </div>
      )
    }
  }

  getDescriptionBaloon(itemData, characteristics, marks) {
    if (characteristics.length) {
      return (
        <div className="ex-description-wrapper">
          {itemData.total ?
            <div className="chartPieListContainer">
              <span className="label-chart">{gettext('Compliance with regulations')}</span>
              <span style={{color: itemData.colorLevel, fontSize: "22px"}}> {itemData.percentage}<small
                style={{color: itemData.colorLevel}}>%</small></span>
              <div className="chartPieList d-flex align-items-center">
                <div style={{marginLeft: "-80px"}}>
                  <Pie data={itemData.chartData} options={CHART_OPTIONS} height={SIZE_PIE_CHART}/>
                </div>
                <div className="chartPieListLegend">
                  {itemData.chartData.labels.map((legend, i) => {
                    const percentage = Number((itemData.chartData.datasets[0].data[i] / (itemData.total / 100)).toFixed(2));
                    if (percentage) {
                      return (
                        <p key={i}>
                          <span className="chartPieLegendColor"
                                style={{background: itemData.chartData.datasets[0].backgroundColor[i]}}/>
                          <span
                            className="chartPieLegendText">{legend}: </span><span>{percentage}<small>%</small></span>
                        </p>)
                    } else
                      return null;
                  })}
                </div>
              </div>
            </div>
            : null}
          <p>
            <span style={{fontWeight: "bold"}}>{gettext('Number problem')}: </span>
            {itemData.numProblem}
          </p>
          <ul className="ex-attrs p-0">
            {characteristics.map(
              (child, i) =>
                child.values.length < 5 ?
                  <li data-path={child.path} key={i}
                      data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:</strong>&nbsp;
                    {child.values.join("; ")}
                  </li>
                  :
                  <li data-path={child.path} key={i}
                      data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:</strong>&nbsp;
                    {child.values.join("; ").split('; ', 5).join("; ")}...
                  </li>
            )}
          </ul>
          <ul className="ex-tags">
            {marks.map(
              (child, i) =>
                child.values.length < 3 ?
                  <li className="ex-tag"
                      key={i}
                      data-name={child.name}
                      data-path={child.path}
                      data-view-class={child.view_class.join(" ")}>
                    <i className="fa fa-tag"/>&nbsp;
                    {child.values.join(", ")}
                  </li>
                  :
                  <li className="ex-tag"
                      key={i}
                      data-name={child.name}
                      data-path={child.path}
                      data-view-class={child.view_class.join(" ")}>
                    <i className="fa fa-tag"/>&nbsp;
                    {child.values.join(", ").split(', ', 3).join(", ")}...
                  </li>
            )}
          </ul>
        </div>
      );
    }
  }

  getItemContent(itemData, marks, url, data, itemBlock, exRibbons = null) {
    return (
      <div className="wrap-list-item ex-catalog-raiting-item-block"
           onClickCapture={e => {
             ::this.handleMouseClick(e);
           }}>
        <div className="row">

          {exRibbons ?
            <ul className="ex-ribbons">
              {marks.map(
                (child, i) =>
                  <li className="ex-wrap-ribbon"
                      key={i}
                      data-name={child.name}
                      data-path={child.path}
                      data-view-class={child.view_class.join(" ")}>
                    <div className="ex-ribbon">{child.values.join(", ")}</div>
                  </li>
              )}
            </ul> : null
          }

          <div className="col-md-3">
            {itemData.total ?
              <div className="chartDoughnutContainer d-flex justify-content-center align-items-center">
                <Doughnut data={itemData.chartData} options={CHART_OPTIONS} height={SIZE_DOUGHNUT_CHART}/>
                <span style={{color: itemData.colorLevel}}>{itemData.percentage}
                  <small style={{color: itemData.colorLevel}}>%</small>
                </span>
              </div> : null
            }
            <a href={url}>
              <div className="ex-media" dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}/>
            </a>
          </div>

          {itemBlock}
        </div>
      </div>
    )
  }

  getItemBlock(url, data, title, descriptions, descriptionBaloon, shortDescription) {
    return (
      <div className="col-md-9">
        <a href={url}>
          <h4>{title}</h4>
        </a>
        {descriptions.opened[data.id] ? descriptionBaloon : shortDescription}
      </div>
    )
  }

  render() {
    const {data, position, meta, descriptions} = this.props,
      index = position + meta.offset,
      groupSize = data.extra.group_size || 0,
      title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name;

    let url = data.extra && data.extra.url ? data.extra.url : data.entity_url;

    let {bar, request_options} = meta;

    if (bar)
      url = `${url}?terms=${bar}`;
    else {
      bar = request_options.bar;
      url = `${url}?terms=${bar}`;
    }

    const reg = new RegExp("(.*?)(href=\"\/.*\/\")(.*)");
    data.media = data.media.replace(reg, `$1href="${url}"$3`);

    const groupDigit = groupSize ? <span className="ex-digit">{groupSize}</span> : null;

    let liClass = "ex-catalog-item";
    if (descriptions.opened[data.id])
      liClass += " ex-state-description";

    let characteristics = data.short_characteristics || [],
      shortCharacteristics = characteristics,
      marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
      (descriptions.opened[data.id] ? " ex-state-description" : "");

    const shortDescription = this.getShortDescription(shortCharacteristics) || "",
      itemData = this.getVotingData(data),
      descriptionBaloon = this.getDescriptionBaloon(itemData, characteristics, marks) || "",
      itemBlock = this.getItemBlock(url, data, title, descriptions, descriptionBaloon, shortDescription),
      itemContent = this.getItemContent(itemData, marks, url, data, itemBlock);

    return (
      <div className={className}
           onMouseOver={e => this.handleMouseOver(e)}
           onMouseOut={e => this.handleMouseOut(e)}
           style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}
